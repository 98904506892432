import React from 'react';
import styled from '@emotion/styled';
import WorldIcon from 'images/world-icon.inline.svg';
import OpenedSvg from "../../images/opened";

const Language = styled(({ className, active, langs }) => {
  let alreadyActive = '';
  for (let lang of langs) {
    if (lang.key == active) {
      alreadyActive = lang;
    }
  }
  return (
    <span className={className}>
      <WorldIcon/>
      <span>{alreadyActive.text}</span>
      <OpenedSvg />
      <ul>
        {langs.map((lang) => {
          if (lang.key != alreadyActive.key) {
            return (
              <li key={lang.text}>
                <a href={lang.link}>{lang.text}</a>
              </li>
            );
          }
        })}
      </ul>
    </span>
  );
})`
  color: #ffffffeb;
  font-weight: 600;
  position:relative;
  max-width: 100%;
  &:hover ul {
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;
    background: #fff;
    z-index: 100;
    border-radius: 0 0 3px 3px;
  }
  &:hover ul li a {
    display: block;
    color: #0D1F3C;
    text-decoration: none;
    background: #030f1d;
    height: 50px;
    line-height: 45px;
    color: #fff;
    font-weight: 600;
    text-align: center;
    transition: all 0.5s linear;
  }
  &:hover ul li a:hover {
    background: #0656B7;
  }
  svg {
    margin: 0 8px;
    vertical-align: sub;
  }
  svg path {
    fill: #cccfd1;
  }
  a {
    color: #ffffffeb;
  }
  ul {
    display: none;
    position: absolute;
    width: 100%;
    top: 67px;
    left: 0;
    background: transparent !important;
  }
  ul li a {
    text-align: center;
    padding: 0 15px;
  }
  ul li:last-child a {
     border-radius: 0 0 3px 3px;
  }
`;
export default Language;
