import React from 'react';
import { graphql } from 'gatsby';
import MDXRenderer from 'gatsby-plugin-mdx/mdx-renderer';
import styled from '@emotion/styled';
import { Layout, PreviousNext, Seo } from '$components';
import config from 'config';
import emoji from '../utils/emoji';
import { onMobile, onTablet } from '../styles/responsive';

const Title = styled.h1`
  font-size: 24pt
  line-height: 1.5;
  font-weight: 500;
  border-left: 2px solid ${(props) => props.theme.colors.primary};
  padding: 0 16px;
  flex: 1;
  margin-top: 0;
  ${onTablet} {
    font-size: 22pt;
  }
  ${onMobile} {
    font-size: 20pt;
  }
`;

const PageTitle = styled.div`
  display: flex;
  flex-flow: wrap;
  align-items: center;
  padding-bottom: 0;
  border-bottom: none;
  color: ${(props) => props.theme.content.titleFont};
  ${onMobile} {
    padding: 15px;
    margin-bottom: 0;
  }
`;

const TitleWrapper = styled.div`
  border-left: 6px solid #1369D0;
  padding-left: 20px;
  flex-basis: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  margin-top: 16px;
`;

const ContentWrapper = styled.div`
  color: ${(props) => props.theme.content.font};
  flex: 1;
  code {
    background: #CFD8E3;
    border: 1px solid #B7C3D2;
    border-radius: 4px;
    padding: 2px 6px;
    font-size: 0.9375em;
    color: #2b2842;
    // overflow-wrap: break-word;
  }
  section {
    margin: 24px 0;
  }
  ul,
  ol {
    -webkit-padding-start: 40px;
    -moz-padding-start: 40px;
    -o-padding-start: 40px;
    margin: 12px 0px;
    padding: 0 15px;
  }

  ul li,
  ol li {
    line-height: 1.8;
    font-weight: 400;
    color: #2b2842;
    font-size: 0.5rem;
    letter-spacing: .01em;
    color: #1369D0;
    margin-bottom: 20px;
  }

  ul li p,
  ol li p {
    margin-left: 15px;
  }

  ol {
    position: relative;
    counter-reset: section;
  }

  ol li {
    margin-top: 12px;
    margin-bottom: 12px;
  }

  ol li:before {
    font-size: .75em;
    line-height: calc(1.75 / .75);
    counter-increment: section;
    content: counters(section, ".") " ";
    position: absolute;
    left: 0;
    color: #8A94A9;
    font-size: 14px;
    font-weight: 600;
  }
`;

const ReadingTime = styled(({ className, time }) => (
  <span className={className}>Reading time: {time} min</span>
))`
  color: #8A94A9;
  text-decoration: none;
  font-weight: 600;
  font-style: normal;
  font-size: 14px;
  letter-spacing: .01em;
`;

const LastUpdated = styled(({ className, time, name }) => {
  return (
    <span className={className}>
      Last update:{' '}
      <i>
        <b>{time}</b>
      </i>{' '}
      by
      <i>
        <b> {name}</b>
      </i>
    </span>
  );
})`
  font-size: 12px;
  display: block;
`;

export default class MDXRuntimeTest extends React.Component {
  componentDidMount() {
    if (window.location.hash) {
      const element = document.getElementById(window.location.hash.substring(1));
      element.scrollIntoView(true);
    }
  }

  render() {
    const { data } = this.props;
    if (!data) {
      return null;
    }
    const {
      mdx,
    } = data;

    // meta tags
    const metaTitle = mdx.frontmatter.metaTitle;
    const docTitle = emoji.emojify(mdx.fields.title);
    const headTitle = metaTitle ? metaTitle : emoji.clean(docTitle);
    return (
      <Layout {...this.props}>
        <Seo frontmatter={mdx.frontmatter} url={this.props.location.href} title={headTitle} />
        <PageTitle>
          <TitleWrapper>
            <Title>{docTitle}</Title>
          </TitleWrapper>
          {(config.features.showMetadata === true && mdx.frontmatter.showMetadata !== false) ||
          mdx.frontmatter.showMetadata === true ? (
            <div css={{ display: 'block' }}>
              <ReadingTime time={mdx.timeToRead * 2} />
            </div>
          ) : (
            ''
          )}
        </PageTitle>
        <ContentWrapper>
          <MDXRenderer>{mdx.body}</MDXRenderer>
        </ContentWrapper>
        {(config.features.previousNext.enabled === true &&
          mdx.frontmatter.showPreviousNext !== false) ||
        mdx.frontmatter.showPreviousNext ? (
          <div css={{ padding: '30px 0' }}>
            <PreviousNext mdx={mdx} />
          </div>
        ) : (
          ''
        )}
      </Layout>
    );
  }
}

export const pageQuery = graphql`
  query($id: String!) {
    site {
      siteMetadata {
        title
        docsLocation
        docsLocationType
        editable
      }
    }
    mdx(fields: { id: { eq: $id } }) {
      fields {
        id
        title
        slug
      }
      body
      tableOfContents
      timeToRead
      frontmatter {
        metaTitle
        showMetadata
        editable
        showPreviousNext
        showToc
      }
    }
  }
`;
