module.exports = {
    "metadata": {
        "name": "Mevspace Docs",
        "short_name": "Mevspace Docs",
        "description": "Mevspace Docs - documentation",
        "language": "en",
        "url": "http://localhost",
        "pathPrefix": "/en",
        "gaTrackingId": "G-3XWW6XTZLM",
        "siteImage": null,
        "favicon": "/assets/favicon.ico",
        "themeColor": "#ff0000"
    },
    "header": {
        "logo": "/assets/code.svg",
        "logoLink": "/",
        "helpUrl": "",
        "links": [
            {
                "text": "",
                "link": ""
            }
        ],
        "enabled": true
    },
    "sidebar": {
        "enabled": true,
        "ignoreIndex": false,
        "forcedNavOrder": [],
        "expanded": [],
        "groups": [
            {
                "order": 1,
                "path": "/tutorials"
            },
            {
                "order": 2,
                "path": "/faq"
            },
            {
                "order": 3,
                "path": "/articles"
            },
            {
                "order": 4,
                "path": "/data-centers"
            }
        ],
        "links": [
            {
                "text": "Return to Mevspace.com",
                "link": "https://mevspace.com"
            }
        ],
        "poweredBy": {
            "trademark": "/assets/gatsby.png",
            "name": "GatsbyJS",
            "link": "https://www.gatsbyjs.org"
        }
    },
    "pwa": {
        "enabled": false,
        "manifest": {
            "name": "BooGi",
            "short_name": "BooGi",
            "start_url": "/",
            "background_color": "#6b37bf",
            "theme_color": "#6b37bf",
            "display": "standalone",
            "crossOrigin": "anonymous",
            "icon": "/assets/favicon.png"
        }
    },
    "social": {
        "facebook": "",
        "github": false,
        "gitlab": "",
        "instagram": "",
        "linkedin": false,
        "mail": "",
        "gmail": "",
        "slack": "",
        "twich": "",
        "twitter": false,
        "youtube": ""
    },
    "features": {
        "editOnRepo": {
            "editable": false,
            "location": "https://github.com/filipowm/boogi",
            "type": "github"
        },
        "search": {
            "enabled": true,
            "indexName": "docs",
            "algoliaAppId": null,
            "algoliaSearchKey": null,
            "algoliaAdminKey": null,
            "excerptSize": 8000,
            "engine": "localsearch",
            "placeholder": "Search",
            "startComponent": "input",
            "debounceTime": 380,
            "snippetLength": 23,
            "hitsPerPage": 10,
            "showStats": true,
            "localSearchEngine": {
                "encode": "advanced",
                "tokenize": "full",
                "threshold": 2,
                "resolution": 30,
                "depth": 20
            },
            "pagination": {
                "enabled": true,
                "totalPages": 10,
                "showNext": true,
                "showPrevious": true
            }
        },
        "toc": {
            "show": true,
            "depth": 4
        },
        "previousNext": {
            "enabled": true,
            "arrowKeyNavigation": true
        },
        "scrollTop": true,
        "showMetadata": true,
        "propagateNetlifyEnv": true,
        "pageProgress": {
            "enabled": true,
            "excludePaths": [
                "/"
            ],
            "height": 3,
            "prependToBody": false,
            "color": "#A05EB5",
            "includePaths": [
                "/tutorials/settingup/features"
            ]
        },
        "mermaid": {
            "language": "mermaid",
            "theme": "dark",
            "options": {},
            "width": 400,
            "height": 300
        },
        "rss": {
            "enabled": false,
            "showIcon": false,
            "title": "BooGi Docs",
            "copyright": "2020, Mateusz Filipowicz",
            "webMaster": "Mateusz Filipowicz",
            "managingEditor": "Mateusz Filipowicz",
            "categories": [
                "Docs as Code",
                "GatsbyJS"
            ],
            "ttl": 60,
            "matchRegex": "^/",
            "outputPath": "/rss.xml",
            "generator": "boogi"
        },
        "darkMode": {
            "enabled": false,
            "default": false
        },
        "publishDraft": false,
        "fullScreenMode": {
            "enabled": false,
            "hideHeader": true,
            "hideToc": true,
            "hideSidebar": true
        },
        "language": {
            "active": "EN",
            "langs": [
                {
                    "key": "PL",
                    "text": "Polski",
                    "link": "/pl"
                },
                {
                    "key": "EN",
                    "text": "English",
                    "link": "/en"
                },
                {
                    "key": "RU",
                    "text": "Russian",
                    "link": "/ru"
                }
            ]
        }
    }
};